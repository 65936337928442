import CrudService from "../../services/CrudService";
import { loadOptionsAsync } from "@/helpers/apiHelper.js";

const namespaced = true;

const state = {};

const mutations = {
  SET_FIELD_VALUES(state, { payload, identifier }) {
    state[identifier].fieldValues = payload;
  },

  SET_METADATA(state, { payload, identifier }) {
    state[identifier].metadata = payload;
  },

  SET_NEW_TOOLLIJSTEN_FORM(state, identifier) {
    state[identifier] = {
      fieldValues: {},
      metadata: {},
      toollijstToolsMetadata: {},
      toolsMetadata: {},
      availableToolsForToollijst: [],
      isInMachine:true,
      isInUnfinishedWissellijst: false
    };
  },

  SET_TOOLLIJST_TOOLS_METADATA(state, { payload, identifier }) {
    state[identifier].toollijstToolsMetadata = payload;
  },

  SET_TOOLLIJST_TOOLS_DATA(state, { payload, identifier }) {
    state[identifier].fieldValues.tools = payload;
  },

  SET_TOOLS_METADATA(state, { payload, identifier }) {
    state[identifier].toolsMetadata = payload;
  },
  SET_AVAILABLE_TOOLS_FOR_TOOLLIJST(state, {payload, identifier}) {
    state[identifier].availableToolsForToollijst = payload;
  },
  SET_IS_IN_MACHINE(state, { payload, identifier }) {
    state[identifier].isInMachine = payload;
  },

  SET_IS_IN_UNFINISHED_WISSELLIJST(state, {payload, identifier}) {
    state[identifier].isInUnfinishedWissellijst = payload;
  },

  CHANGE_EXTERNAL_TOOLNUMBER(state, { id, externToolnumber, identifier}) {
    state[identifier].fieldValues.tools.forEach(t => {
      if(t.id == id) {
        t.externalToolnummer = externToolnumber;
      }
    });
  }
};

const actions = {
  getMetadata({ commit }, { identifier, isUpdate }) {
    CrudService.getMetadataByName("toollijsten", "form")
      .then(async (response) => {
        // Filter out the ones that are not editable only if it is add
        response.data.properties = response.data.properties.filter(
          (prop) => (!isUpdate && prop.variableName !== "ingelezen") || prop.editable
        );

        // Ensures we are not loading the same endpoint multiple times
        await loadOptionsAsync(
          response.data.properties,
          response.data.endpoint
        );
        response.data.stretchInputFields = true;
        commit("SET_METADATA", { payload: response.data, identifier });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getFieldValues({ commit }, { id, identifier }) {
    CrudService.getDataByQuery(`toollijsten/${id}`, "includeChildren", true)
      .then((response) => {
        if (response.data.success) {
          commit("SET_FIELD_VALUES", {
            payload: response.data.value,
            identifier,
          });
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToollijstToolsMetadata({ commit }, identifier) {
    CrudService.getMetadataByName("toollijsttools", "grid")
      .then((response) => {
        response.data.properties.forEach(p => {
          if(p.variableName == 'aantal' || p.variableName == 'externalToolnummer') {
            p.editable = true;
          }
        });
        commit("SET_TOOLLIJST_TOOLS_METADATA", { payload: response.data, identifier });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsMetadata({ commit }, identifier) {
    CrudService.getMetadataByName("tools", "grid")
      .then((response) => {
        commit("SET_TOOLS_METADATA", { payload: response.data, identifier });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getAvailableToolsData({ commit }, { payload: machineId, identifier }) {
    CrudService.getGridData(`tools/byMachine/${machineId}`)
      .then((response) => {
        if (response.data.success) {
          commit("SET_AVAILABLE_TOOLS_FOR_TOOLLIJST", { payload: response.data.value, identifier });
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("ADD_TOAST_ERROR", error, { root: true });
        throw error;
      });
  },

  getIsInMachine({commit}, {identifier, id}) {
    CrudService.getGridData(`toollijsten/isInMachine/${id}`)
    .then((response) => {
      if (response.data.success) {
        commit("SET_IS_IN_MACHINE", { payload: response.data.value, identifier });
      }

      commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
    })
    .catch((error) => {
      commit("ADD_TOAST_ERROR", error, { root: true });
      throw error;
    });
  },

  getIsInUnfinishedWissellijst({commit}, {identifier, id}) {
    CrudService.getGridData(`toollijsten/isInUnfinishedWissellijst/${id}`)
    .then((response) => {
      if (response.data.success) {
        commit("SET_IS_IN_UNFINISHED_WISSELLIJST", { payload: response.data.value, identifier });
      }

      commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
    })
    .catch((error) => {
      commit("ADD_TOAST_ERROR", error, { root: true });
      throw error;
    });
  },

  changeExternalToolnumber({commit}, {identifier, id,externToolnumber}) {
    CrudService.putDataWithQuery('toollijsttools/externalToolnumber', id, {queryValue: externToolnumber, queryName: 'toolNumber'})
    .then((response) => {
        if (response.data.success) {
          commit("CHANGE_EXTERNAL_TOOLNUMBER", {id: id, externToolnumber: externToolnumber, identifier:identifier});
        }

        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage:
              "Het externe toolnummer is succesvol gewijzigd.",
          },
          { root: true }
        );
    })
    .catch((error) => {
      commit("ADD_TOAST_ERROR", error, { root: true });
      throw error;
    });
  }
};

const toollijstencrud = {
  state,
  mutations,
  actions,
  namespaced,
};

export default toollijstencrud;
