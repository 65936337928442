<template>
  <CFooter>
    <div>
      Tool management
      <span class="me-1" v-if="version?.productVersion"
        >V{{ version.productVersion.split('+')[0] }}</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://www.jhtoolmanagement.nl">JH Toolmanagement</a>
    </div>
  </CFooter>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "AppFooter",
  setup() {
    const store = useStore();
    const version = computed(() => store.state.version);
    return {
      version,
    };
  },
};
</script>
