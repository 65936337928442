<template>
  <CCard>
    <CCardHeader>
      <a @click="goToToolsView">
        <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
      </a>
      <strong class="ms-3">{{ metadata?.modelDisplayName }} CAM {{ cam }} ({{ toolcode }})</strong>
    </CCardHeader>
    <CCardBody>
      <dynamic-grid
        :name="componentName"
        :data="data"
        :metadata="metadata"
        :autoPagination="true"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { useRouter } from "vue-router";
import { computed, toRefs, watch, ref } from "vue";
import { useStore } from "vuex";
import usePromise from "@/composables/usePromise";
import CrudService from "@/services/CrudService";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";

export default {
  components: { DynamicGrid, CIcon },
  props: {
    toolId: {
      required: true,
    }
  },
  setup(props) {
    const { toolId: toolId } = toRefs(props);
    const router = useRouter();
    const store = useStore();

    const componentName = 'toolhistory';

    const metadata = computed(
      () => store.state.dynamicgridview[componentName].metadata
    );

    /* little bit of a cheaty way; rows is not directly the rows but is a wrapper and we get the toolcode/cam also from there. 
    Alternative is having to duplicate a whole store again while we can just use dynamicgridview for our purpose */
    const data = computed(
      () => store.state.dynamicgridview[componentName].rows.events
    );

    const toolcode = computed(() => store.state.dynamicgridview[componentName].rows.toolcode);
    const cam = computed(() => store.state.dynamicgridview[componentName].rows.camToolnummer);
    watch(
      toolId,
      () => {
        store.commit(
          "SET_LAST_PAGE",
          { name: "dynamicgridview", params: { name: componentName } },
          { root: true }
        );
        
        // Always override last store
        store.commit("dynamicgrid/SET_NEW_GRID", componentName);
        store.commit("dynamicgridview/SET_NEW_GRID_VIEW", componentName);

        if (!metadata.value.properties) {
          const getMetadata = usePromise(() =>
            CrudService.getMetadataByName(componentName, "grid")
          );

          watch(getMetadata.loading, () => {
            if (getMetadata.loading.value === false) {
              store.commit("dynamicgridview/SET_METADATA", {
                payload: getMetadata.results.value.data,
                identifier: componentName,
              });
              store.dispatch("dynamicgridview/getRows", {
                endpoint: `tools/history/${toolId.value}`,
                identifier: componentName,
              });
            }
          });
          getMetadata.createPromise();
        } else {
          store.dispatch("dynamicgridview/getRows", {
            endpoint: `tools/history/${toolId}`,
            identifier: componentName,
          });
        }
      },
      { immediate: true }
    );    

    function goToToolsView() {
      router.push({
        name: 'toolsview',
        params: {tryAvoidReload:true}
      })
    }
    return {
      data,
      metadata,
      componentName,
      toolcode,
      cam,
      goToToolsView,
      cilArrowLeft
    };
  },
};
</script>
