<template>
  <div :key="id ?? formIdentifier">
    <CRow>
      <CCard class="col-md-12">
        <CCardHeader>
          <strong>Toollijst</strong>
          <CButton @click="goToGrid" class="btn btn-primary btn-sm" style="float: right"
            >Annuleren</CButton
          >
          <CButton
            :disabled="validationErrors.length"
            @click="onSubmit()"
            class="btn btn-primary btn-sm"
            style="float: right; margin-right: 4px"
            >Opslaan</CButton
          >
          <CButton
            v-if="!!id && !data?.hasExchanged"
            :disabled="validationErrors.length"
            @click="readToollijst"
            class="btn btn-primary btn-sm"
            style="float: right; margin-right: 4px"
            >Inlezen</CButton
          >
          <span style="float: right; margin-right: 40px; margin-top: 4px">{{
            data?.machine?.machineToollijsten?.filter((x) => x.toollijstId == id)
              ?.length > 0
              ? "Deze toollijst zit in de machine."
              : ""
          }}</span>
          
          <CButton
            v-if="!!id && data?.hasExchanged && !isInUnfinishedWissellijst"
            :disabled="validationErrors.length"
            @click="onSubmit(true)"
            class="btn btn-secondary btn-sm"
            style="float: right; margin-right: 4px"
            >Nieuwe Versie</CButton
          >
        </CCardHeader>

        <CCardBody>
          <dynamic-form
            :metadata="metadata"
            :data="data"
            :name="formIdentifier"
            :isEdit="!!id"
          />
        </CCardBody>
      </CCard>
    </CRow>
    <CRow v-if="!!id && !isInMachine" class="mt-4">
      <CCard>
        <CCardHeader>
          <strong>Tools in toollijst</strong>
          <CButton
            :disabled="!selectedToolInToollijstRow?.id"
            class="btn btn-primary btn-sm ms-2 float-end"
            @click="onRemoveTool"
            >Verwijderen</CButton
          >
          <CButton class="btn btn-primary btn-sm float-end ms-2" @click="onOpenAddModal"
            >Toevoegen</CButton
          >
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            name="ToolsInToollijst"
            :metadata="toollijstToolsMetadata"
            :data="data.tools"
            :autoPagination="true"
          />
        </CCardBody>
      </CCard>
    </CRow>
  </div>
  <div v-if="!!id" :style="modalActive ? undefined : 'display:none'">
    <CModal size="xl" :visible="modalActive" @close="modalActive = false">
      <CModalHeader>
        <CModalTitle>Voeg tool toe aan toollijst</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <label>Snel zoeken</label>
        <input
          class="form-control mb-4"
          style="width: 350px"
          type="text"
          v-model="toolSearch"
        />
        <dynamic-grid
          name="AvailableToolsForToollijst"
          :metadata="toolsMetadata"
          :data="availableToolsForToollijst"
          :autoPagination="true"
        />
      </CModalBody>
      <CModalFooter>
        <CButton @click="modalActive = false" color="secondary">Annuleren</CButton>
        <CButton @click="onContinue" color="primary">Doorgaan</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, toRefs, watch, ref } from "vue";
import DynamicForm from "../dynamicform/DynamicForm.vue";
import DynamicGrid from "../dynamicgrid/DynamicGrid.vue";

import CrudService from "../../services/CrudService";
import { useRouter } from "vue-router";
import moment from "moment";

export default {
  name: "ToollijstenCrud",
  components: { DynamicForm, DynamicGrid },
  props: {
    id: {
      required: false,
    },
    machineId: {
      required: false,
    },
    naam: {
      required: false,
    },
    klant: {
      required: false,
    },
    tekeningnummer: {
      required: false,
    },
    revisienummer: {
      required: false,
    },
    benaming: {
      required: false,
    },
    aanmaakdatum: {
      required: false,
    },
    inleesprocedure: {
      required: false,
    },
    directory: {
      required: false,
    },
    bronbestand: {
      required: false,
    },
    doelbestand: {
      required: false,
    },
  },
  setup(props) {
    const { id: id, machineId: machineId } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    const mainIdentifier = "toollijsten" + Math.floor(Math.random() * 1000000);
    let formIdentifier = "form" + Math.floor(Math.random() * 1000000);

    store.commit("toollijstencrud/SET_NEW_TOOLLIJSTEN_FORM", mainIdentifier);
    store.commit("dynamicform/SET_NEW_FORM", formIdentifier);

    const toolsMetadata = computed(
      () => store.state.toollijstencrud[mainIdentifier].toolsMetadata
    );
    store.dispatch("toollijstencrud/getMetadata", {
      identifier: mainIdentifier,
      isUpdate: !!id.value,
    });

    watch(
      id,
      () => {
        if (id.value) {
          store.dispatch("toollijstencrud/getToollijstToolsMetadata", mainIdentifier);
          store.dispatch("toollijstencrud/getFieldValues", {
            id: id.value,
            identifier: mainIdentifier,
          });

          store.dispatch("toollijstencrud/getIsInMachine", {
            identifier: mainIdentifier,
            id: id.value,
          });
          store.dispatch("toollijstencrud/getIsInUnfinishedWissellijst", {
            identifier: mainIdentifier,
            id: id.value,
          });
          store.commit("dynamicgrid/SET_NEW_GRID", "ToolsInToollijst");
          store.commit("dynamicgrid/SET_NEW_GRID", "AvailableToolsForToollijst");
          store.dispatch("toollijstencrud/getToolsMetadata", mainIdentifier);
        }
      },
      { immediate: true }
    );

    const isInMachine = computed(
      () => store.state.toollijstencrud[mainIdentifier].isInMachine
    );

    const isInUnfinishedWissellijst = computed(
      () => store.state.toollijstencrud[mainIdentifier].isInUnfinishedWissellijst
    );

    const metadata = computed(() => store.state.toollijstencrud[mainIdentifier].metadata);
    const toollijstToolsMetadata = computed(
      () => store.state.toollijstencrud[mainIdentifier].toollijstToolsMetadata
    );

    const data = computed(() => {
      return store.state.toollijstencrud[mainIdentifier].fieldValues;
    });
    const loadingStatus = computed(
      () => store.state.dynamicform[formIdentifier].loadingStatus
    );
    const validationErrors = computed(
      () => store.state.dynamicform[formIdentifier].validationErrors
    );

    const lastPage = computed(() => store.state.lastPage);

    const modalActive = ref(false);
    const availableToolsForToollijst = computed(() => {
      return store.state.toollijstencrud[
        mainIdentifier
      ].availableToolsForToollijst.filter(
        (a) => !data.value.tools.some((t) => t.tool.id == a.id)
      );
    });

    const toolSearch = ref("");
    const ogToolsAvailable = ref([]);
    watch(
      toolSearch,
      () => {
        if (!toolSearch.value) {
          if (ogToolsAvailable.value.length > 0) {
            store.state.dynamicgrid["AvailableToolsForToollijst"].rows =
              ogToolsAvailable.value;
          }
          return;
        }
        if (!ogToolsAvailable.value?.length) {
          ogToolsAvailable.value =
            store.state.dynamicgrid["AvailableToolsForToollijst"].rows;
        }

        store.state.dynamicgrid[
          "AvailableToolsForToollijst"
        ].rows = ogToolsAvailable.value.filter(
          (x) =>
            x.camToolnummer.toString().includes(toolSearch.value) ||
            x.toolCode.toLowerCase().includes(toolSearch.value.toLowerCase())
        );
      },
      { immediate: true }
    );

    watch(
      [machineId, metadata],
      () => {
        if (machineId.value && machineId.value != "-1" && metadata.value) {
          setTimeout(() => {
            store.state.dynamicform[formIdentifier].fieldValues.machineId = parseInt(
              machineId.value
            );
            store.state.toollijstencrud[mainIdentifier].fieldValues.machineId = parseInt(
              machineId.value
            );
          }, 0);
        }
        if (props.naam && metadata.value) {
          setTimeout(() => {
            store.state.dynamicform[formIdentifier].fieldValues.toollijstNaam =
              props.naam;
            store.state.dynamicform[formIdentifier].fieldValues.klant = props.klant;
            store.state.dynamicform[formIdentifier].fieldValues.tekeningnummer =
              props.tekeningnummer;
            store.state.dynamicform[formIdentifier].fieldValues.revisienummer =
              props.revisienummer;
            store.state.dynamicform[
              formIdentifier
            ].fieldValues.aanmaakdatum = props.aanmaakdatum
              ? moment(
                  props.aanmaakdatum.split(".")[0],
                  "YYYY-MM-DDTHH:mm:ss",
                  true
                ).format("yyyy-MM-DD")
              : props.aanmaakdatum;

            store.state.dynamicform[formIdentifier].fieldValues.benaming = props.benaming;
            store.state.dynamicform[
              formIdentifier
            ].fieldValues.inleesProcedureId = parseInt(props.inleesprocedure);
            store.state.dynamicform[formIdentifier].fieldValues.directory =
              props.directory;
            store.state.dynamicform[
              formIdentifier
            ].fieldValues.bronBestand = props.directory
              ? [props.directory, props.bronbestand]
                  .map(function (i) {
                    return i.replace(/(^\/|\/$)/, "");
                  })
                  .join("\\")
              : props.bronbestand;

            store.state.dynamicform[formIdentifier].fieldValues.doelBestand =
              props.doelbestand;
          }, 0);
        }
      },
      { immediate: true }
    );

    const onSubmit = (archive = false) => {
      store.commit("dynamicform/SET_LOADING_STATUS", {
        loadingStatus: true,
        identifier: formIdentifier,
      });
      const isUpdate = !!id.value;
      const body = store.state.dynamicform[formIdentifier].fieldValues;
      body.tools = data.value?.tools
        ? data.value.tools.map((t) => {
            const rowForEditedUpdates = store.state.dynamicgrid['ToolsInToollijst'].rows.filter(r => r.toolId == t.toolId)[0];
            if (!t.id) t.tool = { opmerking: t.tool.opmerking, id: t.tool.id };
            t.aantal = rowForEditedUpdates?.aantal ?? t.aantal ?? 1;
            t.externalToolnummer = rowForEditedUpdates?.externalToolnummer ?? t.externalToolnummer;
            return t;
          })
        : [];
      body.isArchived = archive;

      const updateOrAdd = () =>
        isUpdate
          ? CrudService.putData("toollijsten", id.value, body)
          : CrudService.postData("toollijsten", body);
      updateOrAdd()
        .then(async (response) => {
          const newId = response.data?.value?.id;

          if (response?.data?.success === false) {
            const errorMsg = response?.data?.messages?.filter(
              (m) => m.messageType == 2
            )[0].text;
            store.commit(
              "ADD_TOAST_ERROR",
              errorMsg ?? "Uw wijzigingen konden niet worden opgeslagen.",
              { root: true }
            );
          } else if (isUpdate) {
            store.commit("ADD_TOAST_SUCCESS", "Uw wijzigingen zijn opgeslagen.", {
              root: true,
            });
            if (store.state.toollijstenview?.toollijstenData?.length) {
              const dataIndex = store.state.toollijstenview.toollijstenData.findIndex(
                (x) => x.id == id.value
              );
              if (dataIndex >= 0) {
                store.state.toollijstenview.toollijstenData[dataIndex] =
                  response.data?.value;
                if (archive) {
                  store.state.toollijstenview.toolsData = [];
                  store.state.dynamicgrid["TLToollijsten"].selectedRows = [];
                }
              }

              if (store.state.toollijstenview.machineSelectedId == body.machineId) {
                const availableIndex = store.state.toollijstenview.toollijstenAvailableToMachine.findIndex(
                  (x) => x.id == id.value
                );
                if (availableIndex >= 0) {
                  store.state.toollijstenview.toollijstenAvailableToMachine[
                    availableIndex
                  ] = response.data?.value;
                }
              }
            }
            if (archive) {
              response.data.value.bronBestand = body.bronBestand;
              response.data.value.directory = body.directory;
              readToollijst(archive, newId, response.data.value);
            }
            goToGrid();
          } else {
            store.commit("ADD_TOAST_SUCCESS", "Uw toollijst is succesvol toegevoegd.", {
              root: true,
            });

            if (store.state.toollijstenview?.toollijstenData?.length) {
              store.state.toollijstenview.toollijstenData.push(response?.data?.value);
              if (store.state.toollijstenview.machineSelectedId == body.machineId) {
                store.state.toollijstenview.toollijstenAvailableToMachine.push(
                  response?.data?.value
                );
              }
            }
            id.value = response.data.value.id;
            router.push({
              name: "toollijstencrud",
              params: {
                name: "toollijsten",
                id: response.data.value.id,
              },
            });
          }
        })
        .catch((err) => store.commit("ADD_TOAST_ERROR", err, { root: true }));
    };

    function goToGrid() {
      router.push(
        lastPage.value ?? {
          name: "toollijsten",
        }
      );
    }

    function readToollijst(archive = false, newId = undefined, response = undefined) {
      CrudService.postData(
        `toollijsten/read/${newId ?? id.value}${!!newId ? `?oldId=${id.value}` : ''}`,
        response ?? store.state.dynamicform[formIdentifier].fieldValues
      )
        .then((response) => {
          if (response.data.success) {
            if (store.state.toollijstenview?.toollijstenData?.length) {
              const dataIndex = store.state.toollijstenview.toollijstenData.findIndex(
                (x) => x.id == newId ?? id.value
              );
              if (dataIndex >= 0) {
                store.state.toollijstenview.toollijstenData[dataIndex] =
                  response.data?.value;
              }

              if (
                store.state.toollijstenview.toollijstenMachineSelectedId ==
                store.state.dynamicform[formIdentifier].fieldValues.machineId
              ) {
                const availableIndex = store.state.toollijstenview.toollijstenAvailableToMachine.findIndex(
                  (x) => x.id == newId ?? id.value
                );
                if (availableIndex >= 0) {
                  store.state.toollijstenview.toollijstenAvailableToMachine[
                    availableIndex
                  ] = response.data?.value;
                }
              }
            }
          }

          if (archive || response.data.success) {
            goToGrid();
          }

          store.commit(
            "HANDLE_RESPONSE",
            {
              response: response.data,
              displayMultipleErrors: true,
              successMessage: "De toollijst is succesvol ingelezen.",
            },
            { root: true }
          );
        })
        .catch((error) => {
          store.commit("SET_ERROR", "De toollijst kon niet worden ingelezen.", {
            root: true,
          });
          throw error;
        });
    }

    function onOpenAddModal() {
      if (!ogToolsAvailable.value?.length) {
        store.dispatch("toollijstencrud/getAvailableToolsData", {
          payload: data.value.machineId,
          identifier: mainIdentifier,
        });
      }

      toolSearch.value = "";
      modalActive.value = true;
    }

    const selectedToolInToollijstRow = computed(
      () => store.state.dynamicgrid["ToolsInToollijst"].selectedRows[0]
    );
    function onRemoveTool() {
      store.state.dynamicgrid["ToolsInToollijst"].rows = store.state.dynamicgrid[
        "ToolsInToollijst"
      ].rows.filter((x) => x.id !== selectedToolInToollijstRow.value.id);
      const index = store.state.toollijstencrud[
        mainIdentifier
      ].fieldValues.tools.findIndex(
        (tool) => tool.id === selectedToolInToollijstRow.value.id
      );

      if (index !== -1) {
        store.state.toollijstencrud[mainIdentifier].fieldValues.tools.splice(index, 1);
      }
    }

    const selectedAvailableTool = computed(
      () => store.state.dynamicgrid["AvailableToolsForToollijst"].selectedRows[0]
    );
    function onContinue() {
      if (!selectedAvailableTool.value) return;

      store.state.toollijstencrud[mainIdentifier].fieldValues.tools.push({
        tool: selectedAvailableTool.value,
      });
      store.state.dynamicgrid["ToolsInToollijst"].rows.push({
        tool: selectedAvailableTool.value,
      });
      modalActive.value = false;
    }

    return {
      metadata,
      data,
      loadingStatus,
      validationErrors,
      onSubmit,
      goToGrid,
      formIdentifier,
      readToollijst,
      toollijstToolsMetadata,
      id,
      modalActive,
      availableToolsForToollijst,
      onOpenAddModal,
      selectedToolInToollijstRow,
      onRemoveTool,
      toolsMetadata,
      onContinue,
      isInMachine,
      toolSearch,
      isInUnfinishedWissellijst
    };
  },
};
</script>
