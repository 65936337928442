/* eslint-disable prettier-vue/prettier */
<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToMagazijnen">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Vervangingen - {{ machine?.naam }}</strong
          >
          <div style="float: right; display: flex">
            <span
              v-if="!replacementsAreUnique || !locatiesAreUnique || !toolnummersAreUnique"
              class="text-danger mt-2 me-3"
              >{{ disabledReason }}</span
            >
            <CFormCheck label="PDF" v-model="printPdf" class="mt-2 me-2" />
            <CButton
              @click="concludeReplacements"
              :disabled="
                !!gridRows.filter(
                  (row) =>
                    !row.finished &&
                    (row.werkelijkeToolBeschikbaar ||
                      row.werkelijkeToolBeschikbaar === undefined)
                ).length ||
                !replacementsAreUnique ||
                !locatiesAreUnique ||
                !toolnummersAreUnique
              "
              color="primary"
              class="btn-md"
            >
              Vervangen Afgerond
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            :name="identifier"
            :metadata="versletenToolsMetadata"
            :data="versletenToolsData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { computed, toRefs, watch, ref } from "vue";
import CrudService from "@/services/CrudService.js";
import { useRouter } from "vue-router";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";
import DownloadClient from "../../services/DownloadClient";

export default {
  name: "TabletVersletenTools",
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true,
    },
    editIds: {
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId, editIds: editIds } = toRefs(props);
    const identifier = ref("TVersletenTools" + machineId.value);
    watch(machineId, () => {
      identifier.value = "TVersletenTools" + machineId.value;
    });

    if (!store.state.dynamicgrid[identifier.value]) {
      store.commit("tabletversletentools/SET_NEW_GRID", identifier.value);
      store.commit("dynamicgrid/SET_NEW_GRID", identifier.value);
      store.dispatch("tabletversletentools/getMagazijnenMetadata");
    }
    if (editIds.value?.length) {
      store.dispatch("tabletversletentools/getMachineMagazijnenByIds", {
        ids: editIds.value,
        machineId: machineId.value,
        identifier: identifier.value,
      });
    }

    store.state.tabletversletentools[identifier.value].fetchedData = [];
    store.state.tabletversletentools[identifier.value].magazijnenData = [];
    store.dispatch("tabletversletentools/getMagazijnenData", {
      machineId: machineId.value,
      identifier: identifier.value,
    });
    store.dispatch("tabletversletentools/getMachine", {
      machineId: machineId.value,
      identifier: identifier.value,
    });

    const versletenToolsMetadata = computed(
      () => store.state.tabletversletentools.magazijnenMetadata
    );
    const fetchedData = computed(
      () => store.state.tabletversletentools[identifier.value].fetchedData
    );
    const versletenToolsData = computed(
      () => store.state.tabletversletentools[identifier.value].magazijnenData
    );

    const gridRows = computed(() => store.state.dynamicgrid[identifier.value].rows);

    const machine = computed(
      () => store.state.tabletversletentools[identifier.value].machine
    );

    const printPdf = ref(false);

    const isToolNumberReserved = (toolNumber) => {
      return (
        machine.value.gereserveerdToolnummerVan !== undefined &&
        machine.value.gereserveerdToolnummerTot !== undefined &&
        toolNumber >= machine.value.gereserveerdToolnummerVan &&
        toolNumber <= machine.value.gereserveerdToolnummerTot
      );
    };

    watch(
      gridRows,
      () => {
        if (
          gridRows.value?.length &&
          gridRows.value.some((x) => x.xStatus === 2 || x.zStatus === 2)
        ) {
          store.commit(
            "SET_WARNING",
            "Er zijn één of meerdere tools waarbij de X of Z waarde buiten de toleranties valt. U kunt er voor kiezen om deze te accepteren, door het vinkje in de kolom Afgerond te zetten.",
            { root: true }
          );
        } else {
          store.commit("CLEAR_WARNING", { root: true });
        }
      },
      { deep: true }
    );

    watch(
      [machine, fetchedData],
      () => {
        if (!machine.value || !fetchedData.value?.length) {
          return;
        }
        CrudService.getGridData("locaties/shouldPropose").then((response) => {
          const copiedData = JSON.parse(JSON.stringify(fetchedData.value));
          const isLocationTaken = (location, gridRows) => {
            return gridRows.some((row) => row.locatie === location);
          };

          if (response.data === true) {
            copiedData
              .filter((x) => !x.standaard)
              .forEach((element) => {
                if(!!element.locatie) return;

                // do not propose location if magazijn is standaard
                if (element?.werkelijkeTool?.machineId) {
                  element.locatie = element?.dropdownConfiguration[1]?.machineProposals.find(
                    (proposal) => !isLocationTaken(proposal.code, copiedData)
                  )?.code;
                }

                if (!element.locatie) {
                  // If no location is available, find one in machineGroepProposals
                  element.locatie = element?.dropdownConfiguration[1]?.machineGroepProposals.find(
                    (proposal) => !isLocationTaken(proposal.code, copiedData)
                  )?.code;
                }
              });
          }

          // Get all used toolnummers
          const usedToolnummers = new Set([
            copiedData
              .filter(
                (tool) =>
                  tool.toolnummer &&
                  tool.toolnummer !== 0 &&
                  !isToolNumberReserved(tool.toolnummer)
              )
              .map((tool) => tool.toolnummer),
            ...machine.value.machineMagazijnen
              .filter(
                (tool) =>
                  tool.toolnummer &&
                  tool.toolnummer !== 0 &&
                  !isToolNumberReserved(tool.toolnummer)
              )
              .map((tool) => tool.toolnummer),
          ]);

          // Get all tools without a toolnummer
          const toolsWithoutToolnummer = copiedData.filter(
            (tool) =>
              !tool.toolnummer ||
              tool.toolnummer === 0 ||
              (isToolNumberReserved(tool.toolnummer) && tool.standaard !== true)
          );
          const toolsWithToolnummer = copiedData.filter(
            (tool) =>
              tool.toolnummer > 0 &&
              (!isToolNumberReserved(tool.toolnummer) || tool.standaard === true)
          );

          // Get all available toolnummers
          const availableToolnummers = Array.from(
            { length: machine.value.totalToolnummers },
            (_, i) => i + machine.value.startToolnummers
          ).filter((toolnummer) => {
            // Exclude if the toolnummer is in the used list
            if (usedToolnummers.has(toolnummer)) {
              return false;
            }

            return !isToolNumberReserved(toolnummer);
          });

          // Assign toolnummers to tools without a toolnummer and add dropdown configuration
          const allAvailable = [...availableToolnummers];

          toolsWithToolnummer.forEach((t) => {
            const config = {
              options: [{ key: undefined, label: "Uw Keuze" }],
              displayTextIfNoOptions: t.toolnummer,
              class: "form-select form-select-sm mb-3",
              valuePropertyName: "toolnummer",
              label: "label",
              key: "key",
            };
            if (!isToolNumberReserved(t.toolnummer)) {
              config.options.push({ key: t.toolnummer, label: t.toolnummer });
            }

            config.options = config.options.concat(
              allAvailable.map((d) => ({ key: d, label: d }))
            );
            t.dropdownConfiguration.push(config);
          });

          for (const toolWithoutToolnummer of toolsWithoutToolnummer) {
            const nextAvailableToolnummer = availableToolnummers[0];
            availableToolnummers.shift();
            if (machine.value.suggestToolnummers) {
              usedToolnummers.add(nextAvailableToolnummer);
              toolWithoutToolnummer.toolnummer = nextAvailableToolnummer;
            }

            if (!toolWithoutToolnummer.dropdownConfiguration)
              toolWithoutToolnummer.dropdownConfiguration = [];
            toolWithoutToolnummer.dropdownConfiguration.push({
              options: [{ key: undefined, label: "Uw Keuze" }].concat(
                allAvailable.map((d) => ({ key: d, label: d }))
              ),
              displayTextIfNoOptions: "n.v.t.",
              class: "form-select form-select-sm mb-3",
              defaultOption: undefined,
              isDefaultOptionDisabled: false,
              valuePropertyName: "toolnummer",
              label: "label",
              key: "key",
            });
          }

          // Commit the updated machineMagazijnen to the store
          store.commit("tabletversletentools/ADD_MACHINE_MAGAZIJNEN", {
            payload: copiedData,
            identifier: identifier.value,
          });
        });
      },
      { immediate: true, deep: true }
    );

    const replacementsAreUnique = computed(() => {
      if (!gridRows.value?.length) {
        return false;
      }
      for (let row of gridRows.value) {
        const replacements = gridRows.value.filter(
          (r) =>
            row.replacement &&
            r.replacement == row.replacement &&
            row.replacement != "Voorinstellen" &&
            row.replacement != "Verwijderen"
        );
        if (replacements.length > 1) {
          return false;
        }
      }
      return true;
    });

    const toolnummersAreUnique = computed(() => {
      if (!gridRows.value?.length) {
        return false;
      }
      for (let row of gridRows.value) {
        const toolnummers = gridRows.value.filter(
          (r) => row.toolnummer && row.toolnummer > 0 && r.toolnummer == row.toolnummer
        );
        if (toolnummers.length > 1) {
          return false;
        }
      }
      return true;
    });

    const locatiesAreUnique = computed(() => {
      if (!gridRows.value?.length) {
        return false;
      }
      for (let row of gridRows.value) {
        const locaties = gridRows.value.filter(
          (r) => row.locatie && r.locatie == row.locatie
        );

        if (locaties.length > 1) {
          return false;
        }
      }
      return true;
    });

    const disabledReason = computed(() => {
      if (!replacementsAreUnique.value) {
        return "De gekozen vervangingen zijn niet uniek.";
      }
      if (!locatiesAreUnique.value) {
        return "De gekozen locaties zijn niet uniek.";
      }

      if (!toolnummersAreUnique.value) {
        return "De gekozen toolnummers zijn niet uniek.";
      }
      return "";
    });

    function printLabels() {
      store.dispatch(
        "tabletversletentools/printLabels",
        gridRows.value.filter((x) => x.printLabel)
      );
    }

    function concludeReplacements() {
      const request = {
        machineId: machineId.value,
        replacements: gridRows.value
          .filter((r) => r.finished)
          .map((row) => {
            return {
              tmId: row.tmId,
              toolnummer:
                row.toolnummer ??
                row.dropdownConfiguration[0].options.filter(
                  (x) =>
                    x.unieknummer == row.replacements &&
                    machineId.value == x.referentieMachineId
                )[0]?.vastToolnummer,
              replacement: row.replacement,
              locatie:
                row.locatie == -1 || row.locatie == "Uw Keuze" ? undefined : row.locatie,
            };
          }),
      };

      CrudService.postData("MachineMagazijnen/vervangingen", request).then((response) => {
        store.commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "Uw wijzigingen zijn opgeslagen.",
            displayMultipleErrors: true,
          },
          { root: true }
        );
        if (response.data.success) {
          if (printPdf.value) {
            downloadPdf(response.data.value.vervangMomentId);
          }
          if (gridRows.value.filter((x) => x.printLabel).length > 0) {
            printLabels();
          }
          if (store.state?.toollijstenview) {
            store.commit("toollijstenview/CLEAR_HISTORY");
          }
        }
        router.push({
          name: "werkvloer",
        });
      });
    }

    function downloadPdf(vervangMomentId) {
      DownloadClient()
        .post("vervangingen/download", [vervangMomentId], {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Vervang Moment ${machine.value.naam}`);
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit("ADD_TOAST_SUCCESS", "Het vervang moment is opgeslagen als pdf.", {
            root: true,
          });
        })
        .catch(() =>
          store.commit(
            "ADD_TOAST_ERROR",
            "Het vervang moment kon niet worden ge�xporteerd.",
            { root: true }
          )
        );
    }

    function goToMagazijnen() {
      router.push({
        name: "tabletmachinemagazijnen",
        params: {
          machineId: machineId.value,
        },
      });
    }

    return {
      versletenToolsMetadata,
      versletenToolsData,
      concludeReplacements,
      gridRows,
      machine,
      replacementsAreUnique,
      goToMagazijnen,
      cilArrowLeft,
      identifier,
      locatiesAreUnique,
      disabledReason,
      printLabels,
      toolnummersAreUnique,
      printPdf,
    };
  },
};
</script>
