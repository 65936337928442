<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWissellijstenHistory">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Wissellijst Regels - {{ machineNaam }}</strong
          >
        </CCardHeader>
        <CCardBody>
          <CButton
            @click="printLabels"
            :disabled="!wissellijstRegels?.filter((x) => x.printLabel)?.length"
            color="primary"
            class="btn-md mb-2"
          >
            Print Labels
          </CButton>
          &nbsp;
          <dynamic-grid
            name="MWissellijstRegelsHistory"
            :metadata="wissellijstenRegelsHistoryMetadata"
            :data="wissellijstenRegelsHistoryData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import { computed, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";

export default {
  name: "TabletWissellijstenHistoryRegels",
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true,
    },
    wissellijstId: {
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId, wissellijstId: wissellijstId } = toRefs(props);

    store.commit("dynamicgrid/SET_NEW_GRID", "MWissellijstRegelsHistory");

    store.dispatch(
      "tabletwissellijstenhistoryregels/getWissellijstenRegelsHistoryMetadata"
    );
    store.dispatch(
      "tabletwissellijstenhistoryregels/getWissellijstenRegelsHistoryData",
      wissellijstId.value
    );

    const machineNaam = computed(
      () => store.state.tabletwissellijstenhistoryregels.machineNaam
    );
    const wissellijstenRegelsHistoryMetadata = computed(
      () =>
        store.state.tabletwissellijstenhistoryregels.wissellijstenRegelsHistoryMetadata
    );
    const wissellijstenRegelsHistoryData = computed(
      () => store.state.tabletwissellijstenhistoryregels.wissellijstenRegelsHistoryData
    );

    watch(
      machineId,
      () => {
        if (machineId.value) {
          store.dispatch("tabletwissellijstenhistoryregels/getMachine", machineId.value);
        }
      },
      { immediate: true }
    );

    const wissellijstRegelSelectedRow = computed(() => {
      if (store.state.dynamicgrid["MWissellijstRegelsHistory"].selectedRows.length > 0) {
        return store.state.dynamicgrid["MWissellijstRegelsHistory"].selectedRows[0];
      }
      return undefined;
    });

    const wissellijstRegels = computed(
      () => {
        return store.state.dynamicgrid["MWissellijstRegelsHistory"].rows;
      }
    );

    function printLabels() {
      store.dispatch(
        "tabletwissellijstenhistoryregels/printLabels",
        wissellijstRegels.value.filter((x) => x.printLabel)
      );
    }

    function goToWissellijstenHistory() {
      router.push({
        name: "tabletmachinewissellijstenhistory",
        params: {
          machineId: machineId.value,
        },
      });
    }

    return {
      wissellijstenRegelsHistoryMetadata,
      wissellijstenRegelsHistoryData,
      wissellijstRegelSelectedRow,
      cilArrowLeft,
      goToWissellijstenHistory,
      wissellijstRegels,
      printLabels,
      machineNaam,
    };
  },
};
</script>
