<template>
  <CRow>
    <CCol :xs="12" :lg="9">
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Tools</strong>
        </CCardHeader>
        <CCardBody>
          <div>
            <CButton
              v-if="hasWriteRole"
              @click="onAdd"
              color="primary"
              class="btn-sm"
            >
              Toevoegen
            </CButton>
            &nbsp;
            <CButton
              v-if="hasWriteRole"
              @click="onEdit"
              :disabled="selectedRow?.id === undefined"
              color="light"
              class="btn-sm"
            >
              Wijzigen
            </CButton>
            &nbsp;
            <CButton
              v-if="hasWriteRole"
              @click="onViewHistory"
              :disabled="selectedRow?.id === undefined"
              color="light"
              class="btn-sm"
            >
              Historie
            </CButton>
            &nbsp;
            <CButton
              v-if="hasWriteRole"
              @click="onViewToollijsten"
              :disabled="selectedRow?.id === undefined"
              color="light"
              class="btn-sm"
            >
              Toollijsten
            </CButton>
            &nbsp;
            <CButton
              v-if="hasWriteRole"
              @click="
                () => {
                  deleteModalActive = true;
                }
              "
              :disabled="
                selectedRow?.id === undefined ||
                selectedRow.werkelijkeTools?.length1
              "
              color="danger"
              class="btn-sm"
              style="float: right"
            >
              Verwijderen
            </CButton>
            &nbsp;
            <select
              class="form-select form-select-sm block"
              @change="onMachineGroepChange"
              size="sm"
              style="width: 300px; float: right; margin-right: 10px"
              :value="[machineGroepSelected ? machineGroepSelected : 'default']"
            >
              <option value="default" selected>Kies een machinegroep</option>
              <option
                v-for="machineGroep in machineGroepen"
                :key="machineGroep"
              >
                {{ machineGroep }}
              </option>
            </select>
          </div>
          &nbsp;
          <dynamic-grid
            @double-clicked="onEdit"
            name="TTools"
            :data="toolsSelected"
            :metadata="toolsMetadata"
            :autoPagination="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12" :lg="3">
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Afbeelding</strong>
        </CCardHeader>
        <div
          @click="
            () => {
              imageModalActive = selectedRow?.files?.length && lastImage.length;
            }
          "
        >
          <CCardBody style="height: 330px">
            <CImage
              class="mb-2"
              style="max-height: 100%"
              v-if="selectedRow?.files?.length && lastImage.length"
              rounded
              thumbnail
              :src="lastImage"
            />
          </CCardBody>
        </div>
        <CCardFooter class="text-muted">{{
          selectedRow?.files?.length && lastImage.length
            ? selectedRow.files
                .filter((f) => f.isMainImage)[0]
                ?.originalName?.substring(0, 20) ??
              "Geen bestandsnaam beschikbaar"
            : "Geen afbeelding beschikbaar"
        }}</CCardFooter>
      </CCard>
    </CCol>
  </CRow>
  <CRow>
    <CCol :xs="12" :lg="6">
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Componenten</strong>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            @double-clicked="openComponentModal"
            name="TComponents"
            :metadata="componentMetadata"
            :data="componentData"
            :autoPagination="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12" :lg="6">
      <CCard>
        <CCardHeader>
          <strong>Werkelijke tools</strong>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            @double-clicked="openWerkelijkeToolModal"
            name="TWerkelijkeTools"
            :metadata="werkelijkeToolsMetadata"
            :data="werkelijkeToolsData"
            :autoPagination="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div :style="werkelijkeToolModalActive ? undefined : 'display:none'">
    <dynamic-modal
      name="TWerkelijkeToolsModal"
      :data="werkelijkeToolModalData"
      :metadata="werkelijkeToolModalMetadata"
      :visible="werkelijkeToolModalActive"
      @close="
        () => {
          werkelijkeToolModalActive = false;
        }
      "
    />
  </div>
  <div :style="componentsModalActive ? undefined : 'display:none'">
    <dynamic-modal
      name="TComponentModal"
      :data="componentModalData"
      :metadata="componentModalMetadata"
      :visible="componentsModalActive"
      @close="
        () => {
          componentsModalActive = false;
        }
      "
    />
  </div>
  <div :style="imageModalActive ? undefined : 'display:none'">
    <CModal
      size="md"
      :visible="imageModalActive"
      @close="
        () => {
          imageModalActive = false;
        }
      "
    >
      <CModalHeader>
        <CModalTitle>{{
          selectedRow?.files?.length
            ? selectedRow.files
                .filter((f) => f.isMainImage)[0]
                ?.originalName?.substring(0, 20) ??
              "Geen bestandsnaam beschikbaar"
            : "Geen afbeelding beschikbaar"
        }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CImage
          v-if="selectedRow?.files?.length"
          rounded
          thumbnail
          :src="lastImage"
        />
      </CModalBody>
    </CModal>
  </div>
  <confirmation-modal
    :modalActive="deleteModalActive"
    modalBody="Weet u zeker dat u de geselecteerde tool wilt verwijderen?"
    @on-continue="onDelete"
    @on-cancel="() => (deleteModalActive = false)"
  />
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import DynamicModal from "@/views/dynamicmodal/DynamicModal.vue";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import { computed, watch, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { fileEndpoint } from "@/services/constants.js";

export default {
  name: "ToolsView",
  components: { DynamicGrid, ConfirmationModal, DynamicModal },
  props: {
    tryAvoidReload: {
      required:false
    }
  },
  setup(props) {
    const {tryAvoidReload:tryAvoidReload} = toRefs(props);
    const store = useStore();
    const router = useRouter();

    if(!tryAvoidReload.value) {
      store.commit("toolsview/CLEAR_HISTORY");
    }

    const toolsData = computed(() => store.state.toolsview.toolsData);
    const toolsMetadata = computed(() => store.state.toolsview.toolsMetadata);
    const toolsSelected = computed(() => store.state.toolsview.toolsSelected);

    const componentMetadata = computed(
      () => store.state.toolsview.componentMetadata
    );
    const componentData = computed(() => store.state.toolsview.componentData);

    const werkelijkeToolsMetadata = computed(
      () => store.state.toolsview.werkelijkeToolsMetadata
    );
    const werkelijkeToolsData = computed(
      () => store.state.toolsview.werkelijkeToolsData
    );

    const lastImage = computed(() => store.state.toolsview.lastImage);
    const machineGroepSelected = computed(
      () => store.state.toolsview.machineGroepSelected
    );

    const imageModalActive = ref(false);
    const deleteModalActive = ref(false);

    const machineGroepen = computed(() => store.state.toolsview.machineGroepen);

    const werkelijkeToolModalMetadata = computed(
      () => store.state.toolsview.werkelijkeToolModalMetadata
    );
    const werkelijkeToolModalData = computed(
      () => store.state.toolsview.werkelijkeToolModalData
    );
    const werkelijkeToolModalActive = ref(false);

    const componentModalMetadata = computed(
      () => store.state.toolsview.componentModalMetadata
    );
    const componentModalData = computed(
      () => store.state.toolsview.componentModalData
    );

    const hasWriteRole = computed(
      () => store.state.user?.roles?.includes("tools") ?? false
    );

    const componentsModalActive = ref(false);

    if(!tryAvoidReload.value) {
      if (!store.state.dynamicgrid["TTools"]) {
        store.commit("dynamicgrid/SET_NEW_GRID", "TTools");
        store.dispatch("toolsview/getToolsMetadata");
      }

      store.commit("dynamicgrid/SET_NEW_GRID", "TWerkelijkeTools");
      store.commit("dynamicgrid/SET_NEW_GRID", "TComponents");

      store.commit("dynamicmodal/SET_NEW_MODAL", "TWerkelijkeToolsModal");
      store.commit("dynamicmodal/SET_NEW_MODAL", "TComponentModal");

      store.dispatch("toolsview/getToolsData");
      store.dispatch("toolsview/getComponentsMetadata");
      store.dispatch("toolsview/getWerkelijkeToolsMetadata");
      store.dispatch("toolsview/getMachineGroepen");
    }
    store.commit("SET_LAST_PAGE", { name: "toolsview" }, { root: true });

    const selectedRow = computed(() => {
      if (store.state.dynamicgrid["TTools"].selectedRows.length > 0) {
        return store.state.dynamicgrid["TTools"].selectedRows[0];
      }
      return undefined;
    });

    const werkelijkeToolsSelectedRow = computed(() => {
      if (store.state.dynamicgrid["TWerkelijkeTools"].selectedRows.length > 0) {
        return store.state.dynamicgrid["TWerkelijkeTools"].selectedRows[0];
      }
      return undefined;
    });

    const componentsSelectedRow = computed(() => {
      if (store.state.dynamicgrid["TComponents"].selectedRows.length > 0) {
        return store.state.dynamicgrid["TComponents"].selectedRows[0];
      }
      return undefined;
    });

    watch(selectedRow, () => {
      if (selectedRow.value) {
        store.commit("toolsview/SET_LAST_IMAGE", {});
        store.dispatch("toolsview/getComponents", selectedRow.value.id);
        store.dispatch("toolsview/getWerkelijkeTools", selectedRow.value.id);

        if (selectedRow.value?.files?.filter((x) => x.isMainImage)?.length) {
          store.dispatch("toolsview/downloadImage", {
            toolId: selectedRow.value.id,
            resourceName: selectedRow.value.files.filter(
              (x) => x.isMainImage
            )[0].resourceName,
          });
        }
      } else {
        store.commit("toolsview/SET_COMPONENT_DATA", []);
        store.commit("toolsview/SET_WERKELIJKE_TOOLS_DATA", []);
      }
    });

    watch(
      toolsData,
      () => {
        if (toolsData.value) {
          onMachineGroepChange({
            target: {
              value: machineGroepSelected.value,
            },
          });
        }
      },
      { immediate: true }
    );

    function onAdd() {
      router.push({
        name: "toolscrud",
      });
    }

    function onEdit() {
      if (hasWriteRole.value) {
        router.push({
          name: "toolscrud",
          params: {
            id: selectedRow.value.id,
          },
        });
      }
    }

    function openWerkelijkeToolModal() {
      if (werkelijkeToolsSelectedRow.value.id) {
        store.dispatch("toolsview/getWerkelijkeToolModalMetadata");
        store.commit(
          "toolsview/SET_WERKELIJKE_TOOL_MODAL_DATA",
          werkelijkeToolsSelectedRow.value
        );
        werkelijkeToolModalActive.value = true;
      }
    }

    function openComponentModal() {
      if (componentsSelectedRow.value.id) {
        store.dispatch("toolsview/getComponentModalMetadata");
        store.commit(
          "toolsview/SET_COMPONENT_MODAL_DATA",
          componentsSelectedRow.value
        );
        componentsModalActive.value = true;
      }
    }

    function onDelete() {
      store.dispatch("toolsview/deleteTool", selectedRow.value.id);
      store.commit("dynamicgrid/REMOVE_ROW", {
        id: selectedRow.value.id,
        identifier: "TTools",
      });
      deleteModalActive.value = false;
      store.commit("dynamicgrid/SET_SELECTED_ROWS", {
        payload: [],
        identifier: "TTools",
      });
    }

    function onMachineGroepChange(event) {
      store.commit("toolsview/SET_MACHINE_GROEP_SELECTED", event.target.value);
      store.commit(
        "toolsview/SET_TOOLS_SELECTED",
        toolsData.value.filter(
          (tool) =>
            machineGroepSelected.value == "default" ||
            !machineGroepSelected.value ||
            tool.machineGroep.naam === machineGroepSelected.value
        )
      );
      store.commit("dynamicgrid/SET_SELECTED_ROWS", {
        payload: [],
        identifier: "TTools",
      });
    }

    function onViewHistory() {
      router.push({
        name: "toolhistory",
        params: {
          toolId: selectedRow.value?.id
        }
      });
    }

    function onViewToollijsten() {
      router.push({
        name: "tooltoollijsten",
        params: {
          toolId: selectedRow.value?.id
        }
      });
    }
    return {
      componentMetadata,
      lastImage,
      componentData,
      werkelijkeToolsMetadata,
      werkelijkeToolsData,
      toolsData,
      toolsMetadata,
      machineGroepen,
      machineGroepSelected,
      onAdd,
      onEdit,
      selectedRow,
      werkelijkeToolsSelectedRow,
      toolsSelected,
      onMachineGroepChange,
      baseUrl: store.state.baseURL,
      fileEndpoint,
      imageModalActive,
      onDelete,
      deleteModalActive,
      werkelijkeToolModalActive,
      werkelijkeToolModalMetadata,
      openWerkelijkeToolModal,
      werkelijkeToolModalData,
      componentsModalActive,
      componentModalData,
      componentModalMetadata,
      openComponentModal,
      hasWriteRole,
      onViewHistory,
      onViewToollijsten
    };
  },
};
</script>
