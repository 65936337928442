import CrudService from "../../services/CrudService";
import ToollijstService from "../../services/ToollijstService";

const namespaced = true;

const state = {
  toollijstenMetadata: {},
  toollijstenWithoutMachineMetadata: {},
  toollijstenData: [],
  toollijstenAvailableToMachine: [],
  componentenMetadata: {},
  componentenData: [],
  toolsMetadata: {},
  toolsData: [],
  magazijnenMetadata: {},
  magazijnenData: [],
  machines: [],
  machineToollijstenData: [],
  toollijstenPerToolData: [],
  machineSelectedId: -1,
  machineHasVoorinstelOrderRegelsFromWissellijst: false,
};

const mutations = {
  SET_TOOLLIJSTEN_METADATA(state, payload) {
    state.toollijstenMetadata = payload;
  },

  SET_TOOLLIJSTEN_WITHOUT_MACHINE_METADATA(state, payload) {
    state.toollijstenWithoutMachineMetadata = payload;
  },

  SET_TOOLLIJSTEN_DATA(state, payload) {
    state.toollijstenData = payload;
  },

  SET_COMPONENTEN_METADATA(state, payload) {
    state.componentenMetadata = payload;
  },

  SET_COMPONENTEN_DATA(state, payload) {
    state.componentenData = payload;
  },

  SET_TOOLS_METADATA(state, payload) {
    state.toolsMetadata = payload;
  },

  SET_TOOLS_DATA(state, payload) {
    state.toolsData = payload;
  },

  SET_MAGAZIJNEN_METADATA(state, payload) {
    state.magazijnenMetadata = payload;
  },

  SET_MAGAZIJNEN_DATA(state, payload) {
    state.magazijnenData = payload;
  },

  SET_MACHINES_DATA(state, payload) {
    state.machines = payload;
  },

  SET_MACHINE_TOOLLIJSTEN_DATA(state, payload) {
    state.machineToollijstenData = payload;
  },

  SET_TOOLLIJST_PER_TOOL_DATA(state, payload) {
    state.toollijstenPerToolData = payload;
  },

  SET_TOOLLIJSTEN_AVAILABLE_TO_MACHINE(state, payload) {
    state.toollijstenAvailableToMachine = payload;
  },

  SET_MACHINE_HAS_VOORINSTEL_ORDER_REGELS_FROM_WISSELLIJST(state, payload) {
    state.machineHasVoorinstelOrderRegelsFromWissellijst = payload;
  },

  REMOVE_TOOLLIJST(state, id) {
    const index = state.toollijstenData.findIndex(
      (toollijst) => toollijst.id == id
    );
    state.toollijstenData.splice(index, 1);

    const avIndex = state.toollijstenAvailableToMachine.findIndex(
      (t) => t.id == id
    );
    state.toollijstenAvailableToMachine.splice(avIndex, 1);
  },

  SET_MACHINE_SELECTED_ID(state, id) {
    state.machineSelectedId = id;
  },

  CLEAR_HISTORY(state) {
    state.toollijstenMetadata = {};
    state.toollijstenData = [];
    state.toollijstenAvailableToMachine = [];
    state.componentenMetadata = {};
    state.componentenData = [];
    state.toolsMetadata = {};
    state.toolsData = [];
    state.magazijnenMetadata = {};
    state.magazijnenData = [];
    state.machines = [];
    state.machineToollijstenData = [];
    state.toollijstenPerToolData = [];
    state.machineSelectedId = -1;
  },
};

const actions = {
  getToollijstenMetadata({ commit }) {
    CrudService.getMetadataByName("toollijsten", "grid")
      .then((response) => {
        const copy = JSON.parse(JSON.stringify(response.data));
        copy.properties = copy.properties.filter(
          (p) => p.variableName != "machineId"
        );
        copy.properties.push({
          variableName: "addedToMachineDate",
          displayName: "Toegevoegd aan machine",
          displayType: 4,
          dateTimePickerType: 2,
        });
        commit("SET_TOOLLIJSTEN_METADATA", response.data);
        commit("SET_TOOLLIJSTEN_WITHOUT_MACHINE_METADATA", copy);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToollijstenData({ commit }) {
    CrudService.getGridData("toollijsten")
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOLLIJSTEN_DATA", response.data.value);
          commit("SET_TOOLLIJSTEN_AVAILABLE_TO_MACHINE", response.data.value);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToollijstTools({ commit }, toollijstId) {
    CrudService.getDataByQuery("toollijsttools/byToollijst", "id", toollijstId)
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOLS_DATA", response.data.value);
        } else {
          commit("SET_TOOLS_DATA", []);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getComponents({ commit }, toolId) {
    CrudService.getGridData(`components/byTool/${toolId}`)
      .then((response) => {
        if (response.data.success) {
          commit("SET_COMPONENTEN_DATA", response.data.value);
        } else {
          commit("SET_COMPONENTEN_DATA", []);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsMetadata({ commit }) {
    CrudService.getMetadataByName("toollijsttools", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "print",
          displayName: "Printen",
          displayType: 10,
          pinned: true
        });

        commit("SET_TOOLS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getComponentenMetadata({ commit }) {
    CrudService.getMetadataByName("components", "grid")
      .then((response) => {
        commit("SET_COMPONENTEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMagazijnenMetadata({ commit }) {
    CrudService.getMetadataByName("machinemagazijnen", "grid")
      .then((response) => {
        commit("SET_MAGAZIJNEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachines({ commit }) {
    CrudService.getDataByQuery("machines", "includeChildren", true)
      .then((response) => {
        if (response.data.success) {
          commit(
            "SET_MACHINES_DATA",
            response.data.value.sort((a, b) => a.naam.localeCompare(b.naam))
          );
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", { root: true });
        throw error;
      });
  },

  getMachineToollijstPerTool({ commit }, { tmId, machineId }) {
    ToollijstService.getMachineToollijstPerTool(tmId, machineId)
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOLLIJST_PER_TOOL_DATA", response.data.value);
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", { root: true });
        throw error;
      });
  },

  getMagazijnen({ commit }, machineId) {
    ToollijstService.getMagazijnen(machineId)
      .then((response) => {
        if (response.data.success) {
          commit("SET_MAGAZIJNEN_DATA", response.data.value);
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", { root: true });
        throw error;
      });
  },

  getMachineLijsten({ commit }, machineId) {
    ToollijstService.getMachineLijsten(machineId)
      .then((response) => {
        if (response.data.success) {
          commit(
            "SET_MACHINE_TOOLLIJSTEN_DATA",
            response.data.value.map((x) => x.toollijst)
          );
        }
        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", { root: true });
        throw error;
      });
  },

  printLabels({ commit }, labelsToPrint) {
    CrudService.postData(
      "toollijsttools/printlabels",
      labelsToPrint.map((x) => x.id)
    )
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "De labels worden geprint.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  deleteToollijst({ commit }, toollijstId) {
    CrudService.deleteById("toollijsten", toollijstId)
      .then((response) => {
        if (response.data.success) {
          commit("REMOVE_TOOLLIJST", toollijstId);
          commit("SET_TOOLS_DATA", []);
          commit("SET_COMPONENTEN_DATA", []);
        }

        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage:
              "De geselecteerde toollijst is succesvol verwijderd.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", "De toollijst kon niet worden verwijderd.", {
          root: true,
        });
        throw error;
      });
  },

  readToollijst({ commit }, toollijstId) {
    CrudService.postData(`toollijsten/read/${toollijstId}`, {})
      .then((response) => {
        if (response.data.success) {
          commit("UPDATE_TOOLLIJST", response.data.value);
        }

        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage:
              "De geselecteerde toollijst is succesvol verwijderd.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", "De toollijst kon niet worden ingelezen.", {
          root: true,
        });
        throw error;
      });
  },

  exportToollijstNc({ commit }, toollijstId) {
    CrudService.postData(`toollijsten/exportnc/${toollijstId}`, {})
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage:
              "De geselecteerde toollijst is succesvol geëxporteerd.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", "De toollijst kon niet worden geëxporteerd.", {
          root: true,
        });
        throw error;
      });
  },

  removeFromMachine({commit, state}, toollijstId) {
    CrudService.postData(`toollijsten/removefrommachine/${toollijstId}`, {})
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage:
              "Er is een wissellijst aangemaakt om de toollijst te verwijderen uit de machine.",
          },
          { root: true }
        );
        if(!response.data.success) return;

        const toollijstSelectedRow = state.toollijstenData.filter(x=>x.id === toollijstId)[0];
        const machines = state.machines;
        const machine = machines.filter(
          (m) => m.id == toollijstSelectedRow?.machine.id
        )[0];

        if(response.data.messages.some(m => m.messageType === 1)) {
          if (machine?.machineToollijsten?.length) {
            machine.machineToollijsten = machine.machineToollijsten.filter(x=>x.toollijstId !== toollijstId);
          }
          commit('SET_MACHINES_DATA', machines);
          commit('SET_MACHINE_TOOLLIJSTEN_DATA', state.machineToollijstenData.filter(x=>x.id !== toollijstId));
        }
        else {
          // Cheaty way of still disabling the button after a wissellijst is created without having to reload all data just for this
          machine.wissellijsten.push({status: 1});
        }
      })
      .catch((error) => {
        commit("SET_ERROR", "Er kon geen wissellijst aangemaakt worden om de toollijst uit de machine te halen..", {
          root: true,
        });
        throw error;
      });
  },

  checkIfMachineHasAnyVoorinstelOrderRegelFromWissellijst(
    { commit },
    machineId
  ) {
    CrudService.getDataById(
      "voorinstelorderregels/anyfromwissellijst",
      machineId
    )
      .then((response) => {
        commit(
          "SET_MACHINE_HAS_VOORINSTEL_ORDER_REGELS_FROM_WISSELLIJST",
          response.data.value
        );
      })
      .catch((error) => {
        commit("SET_ERROR", "Netwerk fout.", { root: true });
        throw error;
      });
  },
};

const toollijstenview = {
  state,
  mutations,
  actions,
  namespaced,
};

export default toollijstenview;
