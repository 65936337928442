import CrudService from "../../services/CrudService";
import ToollijstService from "../../services/ToollijstService";
import WissellijstService from "../../services/WissellijstService";

const namespaced = true;

const state = {
  wissellijstMetadata: {},
  toolsInMetadata: {},
  toolsOutMetadata: {},
};

const mutations = {
  SET_WISSELLIJST_METADATA(state, payload) {
    state.wissellijstMetadata = payload;
  },

  SET_TOOLS_IN_METADATA(state, payload) {
    state.toolsInMetadata = payload;
  },

  SET_TOOLS_OUT_METADATA(state, payload) {
    state.toolsOutMetadata = payload;
  },

  SET_TOOLLIJST(state, { payload, identifier }) {
    state[identifier].toollijst = payload;
  },

  SET_MACHINE(state, { payload, identifier }) {
    state[identifier].machine = payload;
  },

  SET_TOOLS_IN_DATA(state, { payload, identifier }) {
    state[identifier].toolsInData = payload;
  },

  SET_TOOLS_OUT_DATA(state, { payload, identifier }) {
    state[identifier].toolsOutData = payload;
  },

  SET_SPACES_LEFT(state, { payload, identifier }) {
    state[identifier].spacesLeft = payload;
  },

  SET_IN_OUT_COMMENTS(state, { payload, identifier }) {
    state[identifier].inOutComments = payload;
  },

  SET_TOOLLIJSTEN_TO_ADD(state, { payload, identifier }) {
    state[identifier].toollijstenToAdd = payload;
  },

  ADD_TOOLLIJST_TO_ADD(state, { payload, identifier }) {
    state[identifier].toollijstenToAdd.push(payload);
  },

  REMOVE_TOOLLIJST_TO_ADD(state, { toollijstId, identifier }) {
    const indexToRemove = state[identifier].toollijstenToAdd.findIndex(
      (t) => t.id == toollijstId
    );
    state[identifier].toollijstenToAdd.splice(indexToRemove, 1);
  },

  SET_WISSELLIJST_DATA(state, { payload, identifier }) {
    state[identifier].wissellijstData = payload;
  },

  SET_WISSELLIJST_COMMENTS(state, { payload, identifier }) {
    state[identifier].wissellijstComments = payload;
  },

  SET_WISSELLIJST_SAVED(state, { payload, identifier }) {
    state[identifier].wissellijstSaved = payload;
  },

  SET_WISSELLIJST_ID(state, { payload, identifier }) {
    state[identifier].wissellijstId = payload;
  },

  SET_IS_SAVE_ENABLED(state, { payload, identifier }) {
    state[identifier].isSaveEnabled = payload;
  },

  SET_WARNINGS_TO_CONFIRM(state, { payload, identifier }) {
    state[identifier].warningsToConfirm = payload;
  },

  CLEAR_HISTORY(state, identifier) {
    state[identifier] = {};
    state[identifier].toollijst = {};
    state[identifier].machine = {};
    state[identifier].toolsInData = [];
    state[identifier].toolsOutData = [];
    state[identifier].machineToollijstenData = [];
    state[identifier].toollijstenToAdd = [];
    state[identifier].toollijstenToRemove = [];
    state[identifier].wissellijstData = [];
    state[identifier].wissellijstComments = [];
    state[identifier].wissellijstSaved = false;
    state[identifier].wissellijstId = undefined;
    state[identifier].isSaveEnabled = false;
  },
};

const actions = {
  getToollijstById({ commit }, { id, identifier }) {
    CrudService.getDataById("toollijsten", id)
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOLLIJST", { payload: response.data.value, identifier });
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachineById({ commit }, { id, identifier }) {
    CrudService.getDataById("machines", id)
      .then((response) => {
        if (response.data.success) {
          commit("SET_MACHINE", { payload: response.data.value, identifier });
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachineToollijstPerTool({ commit }, { tmId, machineId, identifier }) {
    ToollijstService.getMachineToollijstPerTool(tmId, machineId)
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOLLIJST_PER_TOOL_DATA", {
            payload: response.data.value,
            identifier,
          });
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsInOut(
    { commit },
    {
      machineId,
      toollijstIdsIn,
      toollijstIdsOut,
      toollijstIdsUnchanged,
      identifier,
    }
  ) {
    WissellijstService.getToolsInUit({
      machineId,
      toollijstIdsIn,
      toollijstIdsOut,
      toollijstIdsUnchanged,
    })
      .then((response) => {
        if (response.data.success) {
          commit("SET_TOOLS_IN_DATA", {
            payload: response.data.value.toolsIn,
            identifier,
          });
          commit("SET_TOOLS_OUT_DATA", {
            payload: response.data.value.toolsUit,
            identifier,
          });
          commit("SET_SPACES_LEFT", {
            payload: response.data.value.aantalVrij,
            identifier,
          });
          commit("SET_IN_OUT_COMMENTS", {
            payload: response.data.value.meldingen,
            identifier,
          });
          commit("SET_IS_SAVE_ENABLED", { payload: false, identifier });
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getToolsInMetadata({ commit }) {
    CrudService.getMetadataByName("toolsintool", "grid")
      .then((response) => {
        commit("SET_TOOLS_IN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
      });
  },

  getToolsOutMetadata({ commit }) {
    CrudService.getMetadataByName("toolsuittool", "grid")
      .then((response) => {
        commit("SET_TOOLS_OUT_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
      });
  },

  getWissellijstMetadata({ commit }) {
    CrudService.getMetadataByName("wissellijstregels", "grid")
      .then((response) => {
        commit("SET_WISSELLIJST_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
      });
  },

  createWissellijst({ commit }, request) {
    WissellijstService.create(request)
      .then((response) => {
        commit("SET_WISSELLIJST_COMMENTS", {
          payload: response.data.value.meldingen.length ? response.data.value.meldingen : (response.data.success ? [] : ['De wissellijst is onjuist.']),
          identifier: request.identifier,
        });
        commit("SET_WISSELLIJST_DATA", {
          payload: response.data.value.wissellijst.regels,
          identifier: request.identifier,
        });
        commit("SET_IS_SAVE_ENABLED", {
          payload: response.data.success,
          identifier: request.identifier,
        });
        commit("SET_WARNINGS_TO_CONFIRM", {
          payload: response.data.value.specialToolsMeldingen,
          identifier: request.identifier,
        });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
      });
  },
};

const wissellijsten = {
  state,
  mutations,
  actions,
  namespaced,
};

export default wissellijsten;
